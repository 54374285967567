//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    error_404:                  '404',
    pageNotFound:               'Seite nicht gefunden',
    messageTimeFormat:          'HH:mm',
    pageTitle:                  'clausgemacht | Gelato | Pizza',
    home:                       'Startseite',
    stagingPasswordPlaceholder: 'Passwort',
    login:                      'Login',
    email:                      'info@clausgemacht.de',
    loading:                    'Wird geladen...',
    deliveryNotAvailable:       'Lieferung ist aktuell nicht verfügbar',
    phone:                      '0751 185 285 20',
    emailUrl:                   'mailto:info@clausgemacht.de',
    phoneUrl:                   'tel:075118528520',
    soldOutText:                '* Wir sind bis einschließlich {{date}} ausverkauft. Für jeden anderen Tag kannst du allerdings gerne bestellen.',
    soldOutTodayText:           '* Wir sind heute ausverkauft. Für jeden anderen Tag kannst du allerdings gerne bestellen.',
    privacyPolicyUrl:           'https://www.iubenda.com/privacy-policy/87986217',
    termsUrl:                   'https://www.clausgemacht.de/agb',
    imprintUrl:                 'https://www.clausgemacht.de/impressum',
    lulububuUrl:                'https://lulububu.de',
    instagramUrl:               'https://www.instagram.com/clausgemacht',
    cancellationPolicy:         'https://www.clausgemacht.de/widerrufsbelehrung',
    privacyPolicy:              'Datenschutz',
    backToCheckoutButtonText:   'Zurück zur Kasse',
    terms:                      'AGB',
    imprint:                    'Impressum',
    checkout:                   'Warenkorb',
    deactivated:                'Deaktiviert',
    deactivatedHeadline:        'Hey du!',
    deactivatedDefaultText:     'Zur Zeit ist unser Bestellsystem deaktiviert. Wir sind bald wieder für dich da.',
    homeHeadline:               'Pizza bestellen',
    order:                      'Bestellen',
    contactInformation:         'Kontaktdaten',
    back:                       'Zurück',
    deliveryHeadline:           'Wie kommen die Pizzen zu dir?',
    addressHeadline:            'Bitte trage deine Lieferadresse ein',
    productsHeadline:           'Deine Bestellung',
    pizzaQuantityHeadline:      'Wie viele Pizzen möchtest du?',
    tooPizzasButtonText:        'Zeig mir erst die Pizzen',
    checkoutHeadline:           'Warenkorb',
    delivery:                   'Lieferung',
    clickAndCollect:            'Abholung',
    paymentOnline:              'Online',
    paymentOfflineCollection:   'Vor Ort - Bar oder kontaktlos',
    paymentOfflineDelivery:     'Bar an Deiner Haustüre',
    veganLabel:                 'vegan',
    vegetarianLabel:            'vegetarisch',
    pizza:                      'Pizza',
    noDeliveryInThisTimeSlot:   'In diesem Zeitfenster ist leider keine Lieferung möglich. Der nächste freie Zeitpunkt ist am {{nextFreeTimeSlotDate}} um {{nextFreeTimeSlotTime}} Uhr.',
    minimumOrderValueLabel:     '* Der Mindestbestellwert für eine Lieferung beträgt {{minimumOrderValue}} €.',
    drinks:                     'Getränke',
    mondayShort:                'Mo',
    tuesdayShort:               'Di',
    wednesdayShort:             'Mi',
    thursdayShort:              'Do',
    fridayShort:                'Fr',
    saturdayShort:              'Sa',
    sundayShort:                'So',
    januaryShort:               'Jan',
    februaryShort:              'Feb',
    marchShort:                 'Mär',
    aprilShort:                 'Apr',
    mayShort:                   'Mai',
    juneShort:                  'Jun',
    julyShort:                  'Jul',
    augustShort:                'Aug',
    septemberShort:             'Sep',
    octoberShort:               'Okt',
    novemberShort:              'Nov',
    decemberShort:              'Dez',
    components:                 {
        openingHours:       {
            noDelivery:      'Keine Lieferung',
            MONDAY_short:    'MO',
            TUESDAY_short:   'DI',
            WEDNESDAY_short: 'MI',
            THURSDAY_short:  'DO',
            FRIDAY_short:    'FR',
            SATURDAY_short:  'SA',
            SUNDAY_short:    'SO',
        },
        infoOverlay:        {
            deliveryTitle:          'Lieferung',
            deliveryText:           'Ab einem Mindestbestellwert von {{minimumOrderValue}} EUR liefern wir Deine Bestellung zu Dir nach Hause. Bitte beachte, dass Du nur den Backzeitpunkt Deiner Pizzen auswählst. In deiner Bestellbestätigung findest du den Lieferzeitpunkt. Sollte es mal etwas länger dauern, informieren wir Dich telefonisch.',
            bigOrdersTitle:         'Große Bestellungen',
            bigOrdersText:          'Für größere Bestellungen als 8 Pizzen bitten wir Dich, uns telefonisch unter <0>0751/185 285 20</0> zu kontaktieren.',
            cutWishTitle:           'Schneiden auf Wunsch',
            cutWishText:            'Um alle Textureigenschaften unserer neapolitanischen Pizzen zu erhalten und um ein schnelleres Abkühlen zu verhindern, schneiden wir die Pizzen nur auf Wunsch. Bitte dementsprechend im Bestellprozess angeben.',
            heatedDeliveryBoxTitle: 'Beheizte Lieferbox',
            heatedDeliveryBoxText:  'Die Lieferung unserer Pizzen erfolgt in einer beheizten Box, welche durch ein ausgeklügeltes Heizsystem, ein Umluftsystem und ein Thermostat die perfekte Temperatur hält. Dadurch kommt die Pizza wie frisch aus dem Ofen bei Dir zu Hause an.',
            notGlutenFreeTitle:     'Nicht Glutenfrei',
            notGlutenFreeText:      'Wir können leider keine glutenfreien Pizzen anbieten. Wir bitten um euer Verständnis.',
        },
        productInfoOverlay: {
            additionalProductInformation: 'Weitere Produktinformationen',
            allergens:                    'Allergene',
        },
        checkout:           {
            termsAndConditions:    'Ich erkläre mich mit den <0>Allgemeinen Geschäftsbedingungen</0> und <1>Wiederrufsbestimmungen</1> einverstanden. *',
            shipment:              'Versand',
            subTotal:              'Zwischensumme',
            vatLabel:              'Inkl. MwSt.',
            total:                 'Total',
            drinksHeadline:        'Kund:innen bestellten auch',
            orderSubText:          'Bestellung für {{date}} um {{time}} Uhr',
            orderWithCosts:        'Kostenpflichtig bestellen',
            payment:               'Bezahlung',
            paymentText:           'Wie möchtest du bezahlen?',
            payNowButton:          'Jetzt bezahlen',
            paymentHeadline:       'Bezahlung',
            orderPaymentText:      'Bezahle deine Bestellung #{{orderNumber}}',
            noProducts:            'Keine Produkte im Warenkorb',
            noServingTypeSelected: 'Bitte wähle zuerst aus, ob du deine Bestellung abholst oder geliefert bekommen möchtest.',
            minimumOrderValue:     'Der Mindestbestellwert für eine Lieferung beträgt {{minimumOrderValue}} € und wurde noch nicht erreicht.',
        },
        footer:             {
            summerIce:    'Spachteleis im Sommer 🍦',
            winterPizza:  'Neapolitanische Pizza im Winter 🍕',
            lulububuLove: 'Mit <0>❤️</0> gemacht von <1>Lulububu</1>',
            information:  'Information',
            contact:      'Kontakt',
        },
        productItem:        {
            vegan:        'vegan',
            vegetarian:   'vegetarisch',
            alcoholic:    'alkoholisch',
            nonAlcoholic: 'alkoholfrei',
        },
        noteInput:          {
            note:   'Notiz',
            edit:   'Bearbeiten',
            cancel: 'Abbrechen',
            add:    'Hinzufügen',
        },
        addressForm:        {
            firstName:             'Vorname',
            lastName:              'Nachname',
            street:                'Straße',
            houseNumber:           'Hausnummer',
            postalCode:            'PLZ',
            city:                  'Stadt',
            comment:               'Zusätzliche Informationen (z.B. Klingel, Stockwerk, etc.)',
            email:                 'E-Mail',
            phone:                 'Telefonnummer für Rückfragen',
            isInDeliveryRadius:    '* Super, deine Adresse befindet sich im Lieferradius.',
            isNotInDeliveryRadius: '* Schade, deine Adresse befindet sich leider nicht im Lieferradius. Bitte gib eine andere Adresse an oder wähle eine andere Lieferart aus.',
        },
        timeSlotSelector:   {
            headline:                     'Wähle Backtag & Zeit',
            expand:                       'Mehr',
            collapse:                     'Weniger',
            countDownReservationPreText:  '* Dein TimeSlot ist noch für',
            countDownReservationPostText: 'Minuten für dich reserviert',
            today:                        'Heute',
        },
        orderInfoButton:    {
            availableSlots:   'Platz für {{availableSlots}} weitere Pizzen',
            noAvailableSlots: 'Keine Pizzen mehr verfügbar',
            tooManyPizzas:    'Zu viele Pizzen ausgewählt',
            timeSlotPrefix:   'TimeSlot',
            timeSlotSuffix:   'Min. reserviert',
        },
    },
    notifications:              {
        'error':                              'Fehler',
        'info':                               'Info',
        'success':                            'Erfolg',
        'warning':                            'Warnung',
        'waitingTimeInfo':                    'Aufgrund von Wartezeiten kann der Bestellvorgang länger dauern',
        'orderErrorMissingEmail':             'Gib bitte deine E-Mail-Adresse an, Vielen Dank!',
        'timeSlotAlreadyReserved':            'Der gewählte Backzeitpunkt ist leider nicht mehr verfügbar. Bitte wähle einen anderen Zeitpunkt aus.',
        'successfullySubmittedOrder':         'Deine Bestellung wurde erfolgreich versendet, du erhältst eine Bestätigung via Mail',
        'successfullySubmittedOrderInStore':  'Die Bestellung wurde erfolgreich versendet.',
        'failedToSubmitOrder':                'Deine Bestellung konnte leider nicht versendet werden. Bitte versuche es später erneut.',
        'timeSlotReservationExpired':         'Dein TimeSlot ist abgelaufen. Bitte wähle einen neuen aus.',
        'failedToCalculateOrderPrice':        'Der Preis deiner Bestellung konnte nicht berechnet werden. Bitte versuche es später erneut.',
        'paymentFailed':                      'Deine Zahlung konnte leider nicht verarbeitet werden. Bitte versuche es später erneut.',
        'paymentCanceled':                    'Deine Zahlung wurde abgebrochen, die Bestellung wird gelöscht.',
        'paymentSucceeded':                   'Deine Zahlung war erfolgreich.',
        'paymentTimeout':                     'Deine Bestellung wurde aufgrund der nicht erfolgten Zahlung gelöscht. Bitte bestelle erneut.',
        'geocodeErrorInvalidOrderAddress':    'Die Adresse konnte nicht gefunden werden. Bitte überprüfe deine Eingabe.',
        'orderNotComplete':                   'Bitte fülle alle Felder aus.',
        'emailNotValid':                      'Bitte gib eine gültige E-Mail-Adresse an.',
        'pleaseSelectProducts':               'Bitte wähle zuerst ein oder mehrere Produkte aus.',
        'availableSlotsInTimeSlot':           'Es sind noch {{availableSlots}} Pizzen in diesem Zeitfenster verfügbar.',
        'tooManyProductsForTimeSlot':         'Es sind leider nicht mehr genügend Pizzen in diesem Zeitfenster verfügbar. Du hast {{numberOfProducts}} Pizzen ausgewählt, es sind aber nur {{availableSlots}} Pizzen verfügbar.',
        'minOrderValueForDeliveryNotReached': 'Der Mindestbestellwert für eine Lieferung beträgt {{minOrderValue}} €.',
        'failedToCheckAddress':               'Die Adresse konnte nicht überprüft werden. Bitte korrigiere deine Eingabe oder versuche es später erneut.',
        'firstSelectTimeSlot':                'Bitte wähle zuerst einen Backzeitpunkt aus.',
        'timeSlotSoldOut':                    'Für heute sind leider keine Pizzen mehr verfügbar. Für jeden anderen Tag kannst du gerne bestellen.',
        'bookingNotPossible':                 'Sieht so aus, als sind wir für heute ausverkauft. Bitte versuche einen anderen Backzeitpunkt.',
        'deliveryNotPossible':                'Zu diesem Zeitpunkt ist leider keine Lieferung möglich. Bitte suche dir einen anderen Backzeitpunkt aus.',
        'bookingInPastNotPossible':           'Es ist nicht möglich, einen Backzeitpunkt in der Vergangenheit zu wählen.',
    },
});

export default german;
